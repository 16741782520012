<template>
  <div>
    <slot name="prepend" />
    <template v-for="type in value.types">
       <v-chip v-if="type" :key="type.value || type" v-bind="$attrs" label :color="getColor(type.value || type)" class="pibot-times-new-roman ma-1">
        <span class="text-uppercase">
          <slot>{{ getChipText(type.value || type) }}</slot>
        </span>
       </v-chip>
    </template>
    <slot name="append" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'pibot-survey-planning-indicator',
  inheritAttrs: false,
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      inspectionTypes: state => state.surveys.inspectionTypes
    })
  },
  methods: {
    getColor (type) {
      return this.inspectionTypes[type].color
    },
    getChipText (type) {
      const text = []
      // Add name for type
      text.push(this.inspectionTypes[type].text)
      // Add comments if any
      if (this.value.comments[type]) text.push(this.value.comments[type])
      // Return the Array as text
      return text.join('')
    }
  }
}
</script>
