<template>
  <v-dialog v-model="dialog" persistent max-width="25rem">
    <!-- Button -->
    <template #activator="{ on }" v-if="!hideActivator">
      <!-- Bind props/attrs to v-btn -->
      <v-btn
        v-bind="$attrs"
        v-on="on"
        :color="$attrs.color || 'primary darken-1'"
        @click="$emit('click')"
      >
        <slot>Confirm</slot>
      </v-btn>
    </template>

    <!-- Dialog -->
    <v-card>
      <v-card-title class="text-h5">
        <slot name="title">Are you sure?</slot>
      </v-card-title>
      <v-card-text>
        <slot name="text" />
        <v-form lazy-validation>
          <v-text-field
            v-model="pw"
            label="Password"
            required
            counter
            clearable
            autocomplete="new-password"
            :type="hidePw ? 'password' : 'text'"
            :append-icon="hidePw ? 'visibility' : 'visibility_off'"
            :error-messages="passwordErrors"
            @click:append="hidePw = !hidePw"
            @input="$v.pw.$touch()"
            @blur="$v.pw.$touch()"
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click="cancel">
          <slot name="btn-cancel">No thanks</slot>
        </v-btn>
        <v-btn color="primary darken-1" @click="confirm" :disabled="hasErrors" :loading="authPending">
          <slot name="btn-confirm">Confirm</slot>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapState, mapActions } from 'vuex'

export default {
  inheritAttrs: false,
  name: 'pibot-pw-confirm',
  data () {
    return {
      dialog: false,
      pw: '',
      hidePw: true,
      numTries: 0,
      errorResponse: ''
    }
  },
  props: {
    hideActivator: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      auth: state => state.account.auth,
      authPending: state => state.account.authPending
    }),
    passwordErrors () {
      const errors = []
      if (!this.$v.pw.$dirty) return errors
      !this.$v.pw.required && errors.push('Password is required')
      this.errorResponse && errors.push(this.errorResponse)
      return errors
    },
    hasErrors () {
      return this.$v.$invalid || this.$v.$anyError
    }
  },
  methods: {
    ...mapActions({
      authPw: 'account/socket_authPw'
    }),
    confirm () {
      this.numTries++
      this.authPw(this.pw)
        .then(() => {
          this.$emit('confirmed', this.pw)
          this.numTries = 0
          this.dialog = false
          this.pw = ''
          this.$v.$reset()
        })
        .catch(({ message }) => {
          this.errorResponse = message
        })
    },
    cancel () {
      this.dialog = false
      this.pw = ''
      this.$v.$reset()
      this.$emit('canceled')
    }
  },
  validations: {
    pw: {
      required
    }
  }
}
</script>
