import { mapState, mapMutations } from 'vuex'

export const planningMixin = {
  computed: {
    ...mapState({
      numChanges: state => state.surveys.numChanges
    })
  },
  methods: {
    ...mapMutations({
      incrChanges: 'surveys/incrChanges',
      setNumChanges: 'surveys/setNumChanges'
    })
  }
}
