<template>
  <v-dialog v-model="dialog" persistent :max-width="dialogMW">
    <template v-slot:activator="{ on }">
      <!-- Bind props/attrs to v-btn -->
      <v-btn v-bind="$attrs" v-on="on" :class="$vnode.data.staticClass">
        <slot>More info</slot>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        <slot name="title">Info</slot>
      </v-card-title>
      <v-card-text>
        <slot name="text" />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" @click="dismiss">
          <slot name="btn-confirm">Dismiss</slot>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'pibot-info-btn',
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    dismiss () {
      this.dialog = false
      this.$emit('dismissed')
    }
  },
  computed: {
    dialogMW () {
      let mw
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          mw = '100%'
          break
        case 'sm':
          mw = 500
          break
        case 'md':
        case 'lg':
          mw = '50%'
          break
        case 'xl':
          mw = '33%'
          break
      }
      return mw
    }
  }
}
</script>
