<template>
  <!-- Legend -->
  <v-layout justify-start class="v-data-footer comments pt-4 pl-4">
    <v-edit-dialog
      large
      @cancel="onCancel"
      @save="onSave"
    >
      <template #default>
        <p class="wrap-pre-line" v-html="tableFooterText()" />
      </template>

      <template #input>
        <v-textarea
          autofocus
          filled
          full-width
          auto-grow
          persistent-hint
          class="text-caption mt-4"
          name="v-data-footer-comments"
          hint="Edit text shown below table"
          color="primary darken-1"
          v-model="localText"
        />
      </template>
    </v-edit-dialog>
  </v-layout>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'pibot-planning-table-footer',
  data () {
    return {
      localText: ''
    }
  },
  methods: {
    ...mapGetters({
      tableFooterText: 'surveys/tableFooterText'
    }),
    ...mapMutations({
      // incrChanges: 'surveys/incrChanges', // DON'T USE THIS, USE PASSWORD POPUP INSTEAD AND UPDATE IT SEPERATE FROM THE TABLE CONTENTS
      setTableFooterText: 'surveys/setTableFooter'
    }),
    ...mapActions({
      fetchTableFooterText: 'surveys/socket_fetchTableFooter',
      updateTableFooterText: 'surveys/socket_updateTableFooter'
    }),
    resetText () {
      this.localText = this.tableFooterText()
    },
    onSave () {
      this.updateTableFooterText(this.localText)
    },
    onCancel () {
      this.localText = this.tableFooterText()
    }
  },
  created () {
    this.fetchTableFooterText()
      .then(this.resetText)
  }
}
</script>

<style>
</style>
