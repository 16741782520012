<template>
  <div class="pibot-survey-planning">
    <!-- Table -->
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="dataPending"
      loading-text="Loading planning... Please wait"
      dense
      sort-by="name"
      must-sort
      :items-per-page="15"
      class="pibot-survey-planning-table"
    >
      <!-- Slot to replace the default rendering of a row -->
      <template #item="{ item, index }">
        <tr>
          <td
            v-for="header in headers"
            :key="header.value"
            :class="Object.assign(
              getAlignClass(header.align),
              getColorClass(index, header.value),
              {
                'v-data-table__divider': header.divider
              }
            )"
          >
            <!-- Normal content if not editable -->
            <span v-if="!header.editable">{{ item[header.value] }}</span>

            <!-- Normal content if data is revision data -->
            <pibot-survey-planning-indicator v-else-if="!canEdit || isRevisionData" :value="item.data[header.value]" small />

            <!-- Editable content -->
            <v-edit-dialog
              v-else
              large
              :return-value.sync="item.data[header.value]"
              @save="() => snackSave(undefined, index, header.value)"
              @cancel="() => cancel(undefined, item.data[header.value])"
              @open="() => setEditCache(item.data[header.value])"
            >
              <!-- Content -->
              <v-hover #default="{ hover }">
                <pibot-survey-planning-indicator :value="item.data[header.value]" small>
                  <template #append>
                    <v-icon small :class="{'hidden': !hover}">edit</v-icon>
                  </template>
                </pibot-survey-planning-indicator>
              </v-hover>

              <!-- Edit dialog -->
              <template #input>
                <div class="pt-4" v-if="editing">
                  <p>Update inspections</p>
                  <!-- Inspections types select -->
                  <v-combobox
                      :value="editing.types"
                      @input="typesChanged"
                      :items="inspectionTypesValues"
                      :disabled="dataPending"
                      autofocus
                      multiple
                      clearable
                      chips
                      deletable-chips
                      small-chips
                      hide-selected
                      color="primary darken-1"
                      item-color="primary darken-1"
                      label="Inspection types"
                    >
                    <!-- Custom selected item chips -->
                    <template #selection="{ item, parent, selected }">
                      <v-chip
                        v-bind="$attrs"
                        :color="inspectionTypes[item].color"
                        :input-value="selected"
                        label
                        small
                        light
                      >
                        <span class="ellipsis-chip-label pr-2">
                          Type {{inspectionTypes[item].text}}
                        </span>
                        <v-icon
                          v-if="parent.deletableChips"
                          small
                          right
                          @click="parent.selectItem(item)"
                        >cancel</v-icon>
                      </v-chip>
                    </template>
                  </v-combobox>
                  <!-- Comments select -->
                  <v-layout wrap>
                    <v-flex xs12 sm4 class="text-caption"
                      v-for="inspType of editing.types"
                      :key="`comment-select-${inspType}`"
                    >
                      <template>
                        <span>{{`Insp. '${inspectionTypes[inspType].text}' comment`}}</span>
                        <v-radio-group
                          v-model="editing.comments[inspType]"
                          mandatory
                        >
                          <v-radio color="primary darken-1" label="None" :value="0" />
                          <v-radio color="primary darken-1" label="*" value="*" />
                          <v-radio color="primary darken-1" label="**" value="**" />
                          <v-radio color="primary darken-1" label="***" value="***" />
                        </v-radio-group>
                      </template>
                    </v-flex>
                  </v-layout>
                </div>
              </template>
            </v-edit-dialog>
          </td>
        </tr>
      </template>

      <!-- Footer -->
      <template #footer>
        <!-- Info -->
        <v-layout justify-start align-start class="v-data-footer info-btns py-3 px-4">
          <template v-for="inspType in inspectionTypes">
            <pibot-info-btn v-if="inspType.description" small :key="inspType.value" :color="inspType.color" class="mr-3">
              <v-icon left small>info</v-icon>
              <small>Type <span class="pibot-times-new-roman">{{inspType.text}}</span> inspection</small>

              <template #title>
                <span>Type <span class="pibot-times-new-roman">{{inspType.text}}</span> inspection</span>
              </template>

              <template #text>
                <div v-html="inspType.description" />
              </template>
            </pibot-info-btn>
          </template>
        </v-layout>

        <pibot-planning-table-footer />
      </template>
    </v-data-table>

    <!-- Buttons -->
    <v-layout justify-end v-if="canEdit">
      <!-- Discard button -->
      <v-btn class="mr-3" :disabled="editedColumns.length === 0 || isRevisionData" outlined color="primary darken-1" @click="revertChanges">
        <v-icon>settings_backup_restore</v-icon>
      </v-btn>

      <!-- Save button/dialog -->
      <pibot-pw-confirm @confirmed="confirmCb" @canceled="cancel" :loading="dataPending" :disabled="numChanges === 0">
        <template>
          <v-icon>save</v-icon>
        </template>

        <template #text>
          <p>Do you want to save current inspection planning?</p>
        </template>
      </pibot-pw-confirm>
    </v-layout>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template #action>
        <v-btn text @click="snack = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import SurveyIndicator from './SurveyIndicator'
import TableFooter from './TableFooter'
import PwConfirm from '@/components/buttons/PasswordConfirm'
import InfoBtn from '@/components/buttons/Info'
import { planningMixin } from '../utils/mixins/planning.mixin'
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'pibot-survey-planning-table',
  mixins: [planningMixin],
  components: {
    'pibot-survey-planning-indicator': SurveyIndicator,
    'pibot-planning-table-footer': TableFooter,
    'pibot-pw-confirm': PwConfirm,
    'pibot-info-btn': InfoBtn
  },
  data () {
    return {
      snack: false,
      snackColor: '',
      snackText: '',
      itemsCache: null,
      beforeEditingCache: null,
      editing: null,
      editedColumns: []
    }
  },
  computed: {
    ...mapState({
      items: state => state.surveys.surveyData,
      loaded: state => state.surveys.loaded,
      dataPending: state => state.surveys.dataPending,
      revisionsFetched: state => state.surveys.revisionsFetched,
      isRevisionData: state => state.surveys.isRevisionData,
      inspectionTypes: state => state.surveys.inspectionTypes
    }),
    inspectionTypesValues: function () {
      return _.sortBy(_.values(this.inspectionTypes))
    },
    headers () {
      const headers = [
        {
          text: 'Pipeline',
          align: 'left',
          sortable: false,
          editable: false,
          value: 'name',
          divider: true
        }
      ]

      const items = JSON.parse(JSON.stringify(this.items))
      // add columns for the next 7 years
      for (let i = 0; i < 7; i++) {
        const year = moment().startOf('year').add(i, 'year').format('YYYY')
        headers.push(
          {
            text: year,
            align: 'center',
            sortable: false,
            editable: true,
            value: year,
            divider: i < 6
          }
        )

        // Add missing year fields to item data
        for (const item of items) {
          if (!item.data[year]) {
            item.data[year] = {
              types: [],
              comments: []
            }
          }
        }
      }

      this.setSurveyData(items)

      return headers
    },
    canEdit () {
      return this.$ac.can(this.$me.role).updateAny('survey-planning').granted
    }
  },
  methods: {
    ...mapMutations({
      setLoadedState: 'surveys/setLoadedState',
      setSurveyData: 'surveys/setSurveyData'
    }),
    ...mapActions({
      fetchItems: 'surveys/socket_fetchSurveyData',
      updateItems: 'surveys/socket_updateSurveyData',
      fetchRevisions: 'surveys/socket_fetchRevisions'
    }),
    typesChanged (val) {
      // Sort new selection
      val = val.sort()

      this.editing.types = _.map(this.editing.types, type => type.value || type)
      val = _.map(val, type => type.value || type)

      // Find which inspection number was removed
      const removedInspType = this.editing.types.filter(x => !val.includes(x))[0] // https://medium.com/@alvaro.saburido/set-theory-for-arrays-in-es6-eb2f20a61848
      // Set type selection to sorted selection
      this.editing.types = val
      // Set the comment value of the removed inspection number to 0
      if (removedInspType) {
        this.editing.comments[(removedInspType.value || removedInspType)] = 0
      }
    },
    getAlignClass (align) {
      const classes = {}
      classes[`text-${align}`] = true
      return classes
    },
    /**
     * Gets the class styling Object for edited items
     */
    getColorClass (index, column) {
      const wasEdited = this.editedColumns.find(e => e.itemIndex === index && e.column === column) !== undefined && !this.isRevisionData
      return { warning: wasEdited }
    },
    /**
     * Set cache Object to revert to when editing is canceled
     * @see https://medium.com/@nickdenardis/vue-js-return-object-to-previous-state-on-cancel-2fa0f2db700a
     */
    setEditCache (item) {
      // Keep track of the original information
      this.beforeEditingCache = JSON.parse(JSON.stringify(item))
      this.editing = item
    },
    snackSave (message, itemIndex, column) {
      // Check if changes were made
      if (this.editing &&
          this.beforeEditingCache &&
          (JSON.stringify(this.editing) !== JSON.stringify(this.beforeEditingCache))
      ) this.incrChanges()

      // Apply color to changed item
      if (itemIndex && column) {
        // Check if already present in this.editedColumns
        const present = this.editedColumns.find(e => e.itemIndex === itemIndex && e.column === column) !== undefined
        // If not, add to this.editedColumns
        if (!present) this.editedColumns.push({ itemIndex, column })
      }

      this.snack = true
      this.snackColor = 'success'
      this.snackText = message || 'Updated'
    },
    cancel (message, item) {
      if (item) {
        // Revert the Object back to it's previous state
        Object.assign(item, this.beforeEditingCache)
        this.editing = this.beforeEditingCache = null
      }

      this.snack = true
      this.snackColor = 'error'
      this.snackText = message || 'Canceled'
    },
    confirmCb (pw) {
      this.updateItems(pw)
        .then(() => {
          this.snackSave('Inspection Data Updated')
          this.setNumChanges(0)
          /**
           * Revisions have been fetched before,
           * which mean this add-on is active and used.
           * Fetch revisions after succesfull save.
           */
          if (this.revisionsFetched) this.fetchRevisions()
        })
        .catch(({ message }) => {
          this.cancel(message)
        })
    },
    revertChanges () {
      this.editedColumns = []
      this.setSurveyData(this.itemsCache)
      this.setNumChanges(0)
    }
  },
  created () {
    if (this.loaded) return // No need to fetch data again

    this.fetchItems()
      .then(() => {
        this.setLoadedState(true)
        this.itemsCache = JSON.parse(JSON.stringify(this.items))
      })
      .catch(({ message }) => {
        this.cancel(message)
      })
  }
}
</script>

<style>
.pibot-survey-planning-table .v-small-dialog__activator {
  display: block;
  max-width: 100%;
  min-width: 33.333333%;
}

.pibot-survey-planning .wrap-pre-line {
  white-space: pre-line;
}

.pibot-survey-planning .v-icon {
  transition: none !important;
}

.pibot-survey-planning .hidden {
  visibility: hidden;
}
</style>
